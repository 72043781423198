import {
  getData,
  putData,
} from './index';

import {
  DELIVERY_ROUND_GET,
  DELIVERY_ROUND_GET_DATES_BY_MONTH,
  DELIVERY_ROUND_PUT_DATE,
  DELIVERY_ROUND_LOADING,
  DELIVERY_ROUND_ERROR,
} from './types';

const getDeliveryRoundByDay = async (dispatch, date) => {
  const url = `/delivery-rounds/day/${date}`;
  dispatch({
    type: DELIVERY_ROUND_LOADING,
    payload: DELIVERY_ROUND_GET,
  });
  const response = await getData(DELIVERY_ROUND_ERROR, url, dispatch, true);
  if (response.data) {
    dispatch({
      type: DELIVERY_ROUND_GET,
      payload: response.data?.deliveryRound,
    });
  }
};

const updateDateDeliveryRound = async (dispatch, data) => {
  const { _id, ...updatedDeliveryRound } = data;
  const url = `/delivery-rounds/update-date/${_id}`;
  dispatch({
    type: DELIVERY_ROUND_LOADING,
    payload: `${DELIVERY_ROUND_PUT_DATE}-${_id}`,
  });
  const response = await putData(
    DELIVERY_ROUND_ERROR,
    url,
    dispatch,
    updatedDeliveryRound,
    true,
  );
  if (response.data?.day) {
    dispatch({
      type: DELIVERY_ROUND_PUT_DATE,
      payload: response.data.day,
    });
  }
  return response;
};

const getDeliveryRoundsDayByMonth = async (dispatch, month, year) => {
  const url = `/delivery-rounds/month/${month}/${year}`;
  dispatch({
    type: DELIVERY_ROUND_LOADING,
    payload: DELIVERY_ROUND_GET_DATES_BY_MONTH,
  });
  const response = await getData(DELIVERY_ROUND_LOADING, url, dispatch, true);
  if (response.data?.days) {
    dispatch({
      type: DELIVERY_ROUND_GET_DATES_BY_MONTH,
      payload: response.data?.days,
    });
  }
};

export {
  getDeliveryRoundByDay,
  getDeliveryRoundsDayByMonth,
  updateDateDeliveryRound,
};
