import React, {
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { BiSave } from 'react-icons/bi';
import { BsCheck } from 'react-icons/bs';
import Loader from '../../Loader';
import { updateDateDeliveryRound } from '../../../actions/deliveryRounds';
import styles from './delivery-round-day-form.module.scss';

import {
  Textarea,
  InputNumber,
  InputFormatPattern,
  ErrorField,
} from '../../../lib/HooksFormFields';

const DeliveryRoundDayForm = ({
  day,
  handleSuccess,
  isLoading,
  error,
}, ref) => {
  const dispatch = useDispatch();
  const {
    control,
    formState: { errors, isDirty },
    trigger,
    reset,
    handleSubmit,
  } = useForm({
    defaultValues: {
      target: 0,
    },
  });

  async function onSubmit(data) {
    const res = await updateDateDeliveryRound(dispatch, {
      _id: day._id,
      ...data,
    });
    if (res.status === 200) {
      if (handleSuccess) handleSuccess();
      reset({
        start: res.data.day.start || '',
        end: res.data.day.end || '',
        breakHours: res.data.day.breakHours || '',
        comments: res.data.day.comments || '',
        target: res.data.day.target || 0,
      });
    } else {
      reset({
        start: day.start || '',
        end: day.end || '',
        breakHours: day.breakHours || '',
        comments: day.comments || '',
        target: day.target || 0,
      });
    }
    return res.status === 200;
  }

  useImperativeHandle(ref, () => ({
    submit: async () => {
      let isValid = true;
      if (isDirty) {
        isValid = await trigger();
      }
      handleSubmit(onSubmit)();
      return isValid;
    },
  }), [isDirty]);

  // INIT FORM VALUES
  useEffect(() => {
    if (!day) return;
    reset({
      start: day.start || '',
      end: day.end || '',
      breakHours: day.breakHours || '',
      comments: day.comments || '',
      target: day.target || 0,
    });
  }, [day]);

  return (
    <div className={styles.form}>
      <div className={styles.fields}>
        <div className={`${styles['container-field']} ${styles.time}`}>
          <InputFormatPattern
            name="start"
            label="Heure de début"
            control={control}
            placeholder="##:##"
            maxlength={5}
            inline
            widthLabel="150px"
            rules={{
              pattern: {
                value: /(\d{2,2}):(\d{2,2})/,
                message: 'Veuillez renseigner l\'heure de début ##:##',
              },
            }}
            mask="_"
            format="##:##"
            className="secondary"
          />
        </div>
        <div className={`${styles['container-field']} ${styles.time}`}>
          <InputFormatPattern
            name="end"
            label="Heure de fin"
            control={control}
            placeholder="##:##"
            maxlength={5}
            inline
            widthLabel="150px"
            rules={{
              pattern: {
                value: /(\d{2,2}):(\d{2,2})/,
                message: 'Veuillez renseigner l\'heure de fin ##:##',
              },
            }}
            mask="_"
            format="##:##"
            className="secondary"
          />
        </div>
        <div className={`${styles['container-field']} ${styles.time}`}>
          <InputFormatPattern
            name="breakHours"
            label="Temps de pause"
            control={control}
            placeholder="##:##"
            maxlength={5}
            inline
            widthLabel="150px"
            rules={{
              pattern: {
                value: /(\d{2,2}):(\d{2,2})/,
                message: 'Veuillez renseigner le temps de pause ##:##',
              },
            }}
            mask="_"
            format="##:##"
            className="secondary"
          />
        </div>
        <div className={`${styles['container-field']} ${styles.target}`}>
          <InputNumber
            name="target"
            control={control}
            label="Livraison réalisées"
            inline
            widthLabel="150px"
            className="secondary"
          />
        </div>
        <div className={`${styles['container-field']} ${styles.comments}`}>
          <Textarea
            name="comments"
            control={control}
            label="Ajouter un commentaire"
            widthLabel="150px"
          />
        </div>
      </div>
      <div className={styles.containerSubmit}>
        {error && (
          <div className={styles.errorMessage}>
            <ErrorField
              key="error-request"
              message={error}
            />
          </div>
        )}
        {!!Object.keys(errors).length
          && [
            'start',
            'end',
            'breakHours',
            'night',
            'restHours',
          ]
            .map((key) =>
              errors?.[key] ? (
                <div className={styles.errorMessage}>
                  <ErrorField
                    key={`error-${key}`}
                    message={errors?.[key].message}
                  />
                </div>
              ) : null)
            .filter((d) => d)[0]}
        <div className={styles.actions}>
          <button onClick={handleSubmit(onSubmit)}>
            {isLoading ? (
              <span className="icon loader">
                <Loader className="secondary" size={20} />
              </span>
            ) : (
              <>
                {isDirty ? (
                  <span className="icon">
                    <BiSave size="24px" />
                  </span>
                ) : (
                  <span className="icon">
                    <BsCheck size="24px" />
                  </span>
                )}
              </>
            )}
            <span>Valider</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(DeliveryRoundDayForm);
