import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import {
  format,
  parse,
  addMonths,
  getYear,
  getMonth,
} from 'date-fns';
import { fr } from 'date-fns/locale';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { BsArrowLeftShort } from 'react-icons/bs';
import { DELIVERY_ROUND_PUT_DATE } from '../../actions/types';
import { getDeliveryRoundsDayByMonth } from '../../actions/deliveryRounds';
import DeliveryRoundDayForm from '../../components/form/DeliveryRoundDayForm';
import DeliveryRoundTodayForm from '../../components/form/DeliveryRoundTodayForm';
import NotificationsBell from '../../components/NotificationsBell';
import ModalStandalone from '../../lib/ModalStandalone';

import styles from './timetable.module.scss';

const Timetable = () => {
  const dispatch = useDispatch();
  const { deliveryRoundReducer } = useSelector((store) => store);
  const { days, isLoading, error } = deliveryRoundReducer;
  const [currentDate, setCurrentDate] = useState(new Date()); // USE TO NAVIGATE BETWEEN MONTH
  const [selectedDate, setSelectedDate] = useState();
  const listDays = useRef();
  const modalRef = useRef();

  // INIT
  // SET SCROLL TO END
  useEffect(() => {
    if (!listDays.current) return;
    const maxScrollLeft = listDays.current.scrollWidth - listDays.current.clientWidth;
    listDays.current.scrollLeft = maxScrollLeft;

    const today = format(new Date(), 'dd-MM-yyyy', { locale: fr });
    const initDay = days.find((d) => d.date === today);

    if (initDay?.start === null && initDay?.end === null && initDay?.breakHours === null) {
      modalRef.current.open();
    }
    if (!selectedDate && initDay) setSelectedDate(initDay);
  }, [days]);

  useEffect(() => {
    const year = getYear(currentDate);
    const month = getMonth(currentDate) + 1;
    getDeliveryRoundsDayByMonth(dispatch, month, year);
  }, [currentDate]);

  const handleChangeMonth = useCallback((dir = 'next') => {
    let updateDate = addMonths(currentDate, dir === 'next' ? 1 : -1);
    if (updateDate.getTime() > Date.now()) updateDate = new Date();
    setCurrentDate(updateDate);
    setSelectedDate(null);
  }, [currentDate]);

  const getStrDay = (strDate) => {
    const d = parse(strDate, 'dd-MM-yyyy', new Date(), { locale: fr });
    return format(d, 'EEEE', { locale: fr });
  };

  return (
    <>
      <ModalStandalone hideCloseButton ref={modalRef}>
        <DeliveryRoundTodayForm
          day={selectedDate}
          isLoading={isLoading.includes(`${DELIVERY_ROUND_PUT_DATE}-${selectedDate?._id}`)}
          handleClose={() => modalRef.current.close()}
          setSelectedDate={setSelectedDate}
        />
      </ModalStandalone>
      <div className={styles.timetable}>
        <div className={styles.container}>
          <div className={styles.header}>
            <NavLink to="/home">
              <BsArrowLeftShort size={40} />
            </NavLink>
            <h1>Calendrier</h1>
            <div className={styles.notifications}>
              <NotificationsBell color="#05004E" />
            </div>
          </div>
          <div>
            <div className={styles.navigation}>
              <div className={styles.navMonths}>
                <div>
                  <p>Date selectionnée</p>
                  <h2>{format(currentDate, 'LLLL yyyy', { locale: fr })}</h2>
                </div>
                <button className="third" onClick={() => handleChangeMonth('prev')}>
                  <FiChevronLeft size={20} />
                </button>
                <button className={getMonth(currentDate) === getMonth(new Date()) ? 'third disabled' : 'third'} onClick={() => handleChangeMonth('next')}>
                  <FiChevronRight size={20} />
                </button>
              </div>
              <div className={styles.navDays}>
                {days.length > 0 && (
                  <ul ref={listDays}>
                    {days.map((d) => (
                      <li key={`day-${d._id}`}>
                        <div className={selectedDate?._id === d._id ? styles.selected : ''} onClick={() => setSelectedDate(d)}>
                          <p>
                            <span>
                              {parse(d.date, 'dd-MM-yyyy', new Date(), { locale: fr }).getDate()}
                            </span>
                            <br />
                            {getStrDay(d.date)}
                          </p>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              {selectedDate && (
                <DeliveryRoundDayForm
                  day={selectedDate}
                  error={error}
                  isLoading={isLoading.includes(`${DELIVERY_ROUND_PUT_DATE}-${selectedDate._id}`)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Timetable;
