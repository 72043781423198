import {
  DELIVERY_ROUND_GET,
  DELIVERY_ROUND_GET_DATES_BY_MONTH,
  DELIVERY_ROUND_PUT_DATE,
  DELIVERY_ROUND_LOADING,
  DELIVERY_ROUND_ERROR,
} from '../actions/types';

const DEFAULT_STATE = {
  deliveryRound: null,
  days: [],
  isLoading: [],
  error: null,
  changedAt: Date.now(),
};

const deliveryRoundReducer = (state = DEFAULT_STATE, action) => {
  let updatedState = {};
  switch (action.type) {
    case DELIVERY_ROUND_GET:
      updatedState = {
        ...state,
        deliveryRound: action.payload,
        isLoading: state.isLoading?.filter((d) => d !== DELIVERY_ROUND_GET),
        error: null,
      };
      break;
    case DELIVERY_ROUND_GET_DATES_BY_MONTH:
      updatedState = {
        ...state,
        error: null,
        days: action.payload,
        isLoading: state.isLoading?.filter(
          (d) => d !== DELIVERY_ROUND_GET_DATES_BY_MONTH,
        ),
      };
      break;
    case DELIVERY_ROUND_PUT_DATE:
      updatedState = {
        ...state,
        error: null,
        changedAt: Date.now(),
        days: [
          ...state.days.filter((d) => d._id !== action.payload._id), action.payload,
        ].sort((a, b) => new Date(a.isoDate).getTime() - new Date(b.isoDate).getTime()),
        isLoading: state.isLoading?.filter(
          (d) => d !== `${DELIVERY_ROUND_PUT_DATE}-${action.payload._id}`,
        ),
      };
      break;
    case DELIVERY_ROUND_LOADING:
      updatedState = {
        ...state,
        error: null,
        isLoading: [...state.isLoading, action.payload],
      };
      break;
    case DELIVERY_ROUND_ERROR:
      // eslint-disable-next-line no-case-declarations
      let message = action?.payload?.data?.error;
      if (message === 'Unauthorized, a member cannot updated timesheet when it\'s already set') {
        message = 'Vous n\'êtes pas autorisé à modifier vos horaires une fois déclarées, veuillez contacter votre manager.';
      } else if (message === 'Can\'t update delivery round date after admin validation') {
        message = 'Les horaires déclarés ont déjà été validés, si vous voulez les modifier, veuillez contacter votre manager.';
      }
      updatedState = {
        ...state,
        error: message,
        isLoading: [],
      };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default deliveryRoundReducer;
